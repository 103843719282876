import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import AdminTitle from "./AdminTitle";
import { Link, useParams } from "react-router-dom";
import {
  adminProductBrief,
  adminProductDelete,
} from "../../Service/admin-service";

// Import Font Awesome icons
import { FaEdit, FaPlus, FaTrash, FaTrashRestoreAlt } from "react-icons/fa";

export default function ProductList() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { category, brand } = useParams();

  // Function to fetch data
  const getData = async () => {
    try {
      const response = await adminProductBrief(category, brand);
      if (response && response.myData) {
        setData(response.myData);
        setTotalCount(response.totalCount || response.myData.length);
      } else {
        console.error("Invalid response structure:", response);
      }
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  // Function to delete a product
  const handleDeleteProduct = async (_id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const response = await adminProductDelete(_id);
        alert(response.message || "Product deleted successfully");
        getData(); // Refresh the product list
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };

  // Fetch data when component loads
  useEffect(() => {
    getData();
  }, [category, brand]); // Refetch when category or brand changes

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <AdminTitle
            title1={"Admin Product Brief"}
            title2={`Total Products: ${totalCount}`}
          />
          <div className="row">
            <div className="col-lg-2 col-md-6 mb-4 m-3">
              <Link
                to={`/admin/${category}/${brand}/addproduct`}
                className="btn btn-primary mx-1"
              >
                <FaPlus /> Add Product
              </Link>
            </div>
            {data.length > 0 ? (
              data.map((product, index) => (
                <div key={index} className="col-lg-3 col-md-6 mb-4">
                  <div className="card h-100">
                    <img
                      src={product.img}
                      className="card-img-top"
                      alt={product.model_name}
                      style={{ maxHeight: "200px", objectFit: "cover" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{product.model_name}</h5>
                      <div className="d-flex justify-content-between">
                        <Link to={`/admin/update-product/${product._id}`}>
                          <button className="btn btn-primary">
                            <FaEdit /> Edit
                          </button>
                        </Link>
                        <Link to={`/admin/modified-product/${product._id}`}>
                          <button className="btn btn-warning">
                            <FaTrashRestoreAlt /> Modify
                          </button>
                        </Link>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDeleteProduct(product._id)}
                        >
                          <FaTrash /> Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12">
                <p className="text-center">No products found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
