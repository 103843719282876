import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useLocation,useNavigate } from 'react-router-dom';
import { adminGetSingleProduct, adminUpdateProduct } from "../../Service/admin-service";

export default function ProductUpdate() {
  const [formData, setFormData] = useState({});
  const [modifiedFields, setModifiedFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [readMoreContent, setReadMoreContent] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();
  const _id = new URLSearchParams(location.search).get('id');
const navigate=useNavigate()
  useEffect(() => {
    async function fetchProduct() {
      try {
        const response = await adminGetSingleProduct(_id);
        const data = response.data;
        setFormData(data);
        setReadMoreContent(data.readMore || "");
        setLoading(false);
        setShowModal(true);
      } catch (error) {
        console.error("Error fetching product:", error);
        setError("Failed to fetch product data.");
        setLoading(false);
      }
    }

    fetchProduct();
  }, [_id]);

  const handleEditorChange = (content) => {
    setReadMoreContent(content);
    setModifiedFields({ ...modifiedFields, readMore: content });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setImagePreview(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "read_more");

    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dkjztezu0/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUploadedImageUrl(data.secure_url);
      setImagePreview(null); // Clear preview after upload
      setError(null); // Clear any previous error message
    } catch (error) {
      console.error("Image upload failed", error);
      setError("Image upload failed. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const updatedProductData = { ...formData, ...modifiedFields, readMore: readMoreContent };

    try {
        // Update the product using the current _id
        const response = await adminUpdateProduct(_id, updatedProductData);
        alert(response.message);
      
        // Navigate to the same product page using the current _id
        navigate(`/admin/categoryv2/brand/product/${_id}`);  // Use _id directly here
        closeModal();
    } catch (error) {
        console.error("Error updating product:", error);
        setError("Failed to update product. Please try again.");
    } finally {
        setIsSubmitting(false);
    }
};

  const closeModal = () => setShowModal(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Update Product</h5>
        {error && <div className="text-red-600">{error}</div>}
      </div>

      {showModal && 
        ReactDOM.createPortal(
          <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
              <div className="bg-white rounded-lg shadow-xl overflow-hidden max-w-6xl w-full z-50">
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Edit ReadMore
                    </h3>
                    <button
                      type="button"
                      className="text-gray-500 hover:text-gray-700"
                      onClick={closeModal}
                    >
                      <span className="text-xl">&times;</span>
                    </button>
                  </div>
                  <div className="mt-5">
                    <Editor
                      tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/js/tinymce/tinymce.min.js`}
                      value={readMoreContent}
                      onEditorChange={handleEditorChange}
                      init={{
                        license_key: "gpl",
                        height: 400,
                        menubar: true,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | bold italic | alignleft aligncenter alignright | " +
                          "bullist numlist | image | link",
                      }}
                    />
                  </div>
                  <div className="mt-5">
                    <h4 className="text-lg">Upload Image</h4>
                    <input type="file" onChange={handleImageUpload} />
                    {imagePreview && (
                      <div className="mt-2">
                        <img src={imagePreview} alt="Uploaded Image Preview" className="w-full h-auto" />
                      </div>
                    )}
                    {uploadedImageUrl && (
                      <div className="mt-2">
                        <button 
                          type="button" 
                          className="btn btn-secondary"
                          onClick={() => handleEditorChange(`<img src="${uploadedImageUrl}" alt="Uploaded Image" />`)}
                        >
                          Insert Image into Editor
                        </button>
                        <input
                          type="text"
                          className="w-full p-2 border"
                          value={uploadedImageUrl}
                          readOnly
                        />
                        <p className="mt-1 text-sm text-gray-600">
                          Copy the above URL if needed.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-white"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Updating...' : 'Update'}
                  </button>
                </div>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}